.main-footer {
    margin-top: 100px;
}

.total-due {
    padding-left: 20px;
    padding-top: 20px;
}

.transaction-group {
    margin: 15px 0 !important;
}

.create-invoice-group {
    text-align: right;
}

.create-invoice-group button,
.create-invoice-group a {
    margin-left: 15px;
}

.create-invoice-group button svg,
.create-invoice-group a svg {
    margin-right: 5px;
}

.label-group.pb-0 button.addnewpro-label {
    padding-bottom: 6px !important;
}

.filter-button-sec {
    text-align: right;
}

.filter-button-sec button svg {
    margin-right: 5px;
}

.search-group {
    margin-bottom: 30px;
}

.filter-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.table-view-send button {
    padding: 8px 10px;
    margin-left: 5px;
}

.table-view-send button svg {
    margin-right: 5px;
}

.date-picker-icon {
    float: right;
    margin-right: 6px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}

.create-invoice-header {
    display: inline-flex;
    margin-bottom: 30px;
    align-items: center;
}

.create-invoice-header h5 {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
}

.create-invoice-header h5 a {
    line-height: 0;
}

.create-invoice-header .slectbox {
    width: 100px;
    margin-left: 15px;
}

.create-invoice-header .slectbox .custom-selectbox {
    background-color: #ffffff;
}

.custom-selectbox {
    background-color: #ffffff;
}

.select-customer-group .label-group {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.select-customer-group .form-control label,
.select-customer-group .form-group>label {
    font-size: 14px;
}


.select-customer-group .slect-input-group .input-field {
    width: calc(100% - 160px);
}

.label-group.pb-0 button {
    padding-bottom: 0 !important;
}

.addTobill .form-control {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.round-data-section ul {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.round-data-section ul li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.round-data-section ul li .input-select-group label {
    display: inline-flex;
}

.round-data-section ul li .input-select-group {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.round-data-section ul li .input-select-group label {
    display: inline-flex;
}

.round-data-section ul li .input-select-group .round-input {
    width: 100px !important
}

.round-data-section ul li .input-select-group .round-select {
    width: 100px !important;
    margin-left: 15px;
}

.round-data-section ul li .input-select-group .round-select>div {
    background-color: #ffffff;
}

.custom-label {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.03333em;
    opacity: 1;
    text-transform: capitalize;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #344767;
    font-weight: 700;
    display: block;
    width: 100px;
    padding: 5px 0;
}

.balance-amount p {
    text-align: right;
    font-size: 13px;
    padding: 5px 0;
}

.add-sig-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.notes-all-list {
    list-style: decimal;
    margin: 0;
    padding: 20px 16px 0;
}

.notes-all-list li {
    font-size: 13px;
    padding: 5px 0;
}

.add-customer-optional {
    margin-top: 15px;
}

.add-customer-optional .add-customer-radiogroup {
    margin: 15px 0;
    padding-left: 15px;
    width: 100%;
}

.add-customer-optional .add-customer-radiogroup label {
    margin-right: 100px;
}

.customer-pays-you .cus-pay {
    position: absolute;
    right: 15px;
    bottom: 8px;
    color: red;
    font-size: 13px;
}

.formp {
    display: contents;
}

.formp p {
    font-size: 12px;
}

button svg {
    margin-right: 5px;
}

.add-gst-number {
    margin-top: 15px;
}

.add-gst-number a,
.primary-unit a {
    color: #17c1e8;
    font-size: 14px;
}

.add-gst-number h6 {
    font-size: 14px;
    padding-top: 8px;
}

.upload-formate {
    font-size: 13px;
}

.barcode {
    position: relative;
}

.barcode button {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    padding: 0 5px;
    border: 1px solid #d5d5d5;
    background: #d5d5d5;
    border-radius: 6px;
}

.purchase-price {
    position: relative;
}

.purchase-price .custom-selectbox {
    position: absolute;
    width: 150px;
    right: 0;
    bottom: 0;
    background: #d5d5d5;
}

.opening-quantity span {
    font-size: 13px;
    line-height: 21px;
    display: inline-block;
    padding-top: 5px;
}

.search-existing-cus {
    position: relative;
}

.search-existing-cus .search-existing-dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
}

.search-existing-cus .search-existing-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.search-existing-cus .search-existing-dropdown ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px;
}

.search-existing-cus .search-existing-dropdown ul li:nth-child(odd) {
    background-color: #DAE2FC;
}

.search-existing-cus .search-existing-dropdown ul li:nth-child(even) {
    background-color: #EEEEEE;
}

.search-existing-cus .search-existing-dropdown ul li .label-list {
    display: flex;
    flex-direction: column;
}

.search-existing-cus .search-existing-dropdown ul li .label-list label {
    font-weight: bold;
    padding: 0px 0;
}

.search-existing-cus .search-existing-dropdown ul li .value-display {
    font-size: 13px;
}

.search-existing-cus button {
    margin: 15px;
}

.add-card-sec .new-note-btn {
    /* width: 120px; */
    padding-left: 5px;
    padding-right: 5px;
}

.attach-file-sec {
    margin-top: 100px;
}

.attach-file-sec button {
    color: #17c1e8 !important;
    margin-top: 16px;
}

.mark-as-fully {
    text-align: right;
    padding-top: 10px !important;
}

.opening-stock {
    padding-top: 10px !important;
}

.add-product-name {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.add-product-name a {
    color: #17c1e8;
    margin: 5px 5px 0 3px;
}

.invoiceFooter-sec {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 50px 0;
}

.all-tags-customer {
    position: absolute;
    top: 42px;
    display: inline-flex;
    align-items: center;
}


.netAmount-pro svg {
    margin-left: 15px;
    font-size: 22px !important;
}

.netAmount-pro>div {
    display: inline-flex;
    align-items: center;
}

.netAmount-pro>div span {
    font-size: 16px;
    font-weight: ;
}

.product-qty {
    display: inline-flex;
    width: 200px;
    align-items: center;
}

.product-qty button {
    margin: -2px 0 0 -6px;
    border-radius: 0;
}

.unit-qty small {
    text-align: center;
    color: #0EBE4A;
    position: absolute;
    padding-top: 5px;
}

.discount-qty {
    display: inline-flex;
    align-items: center;
    width: 200px;
}

.addbankmodal {
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    color: red;
    border: 1px solid #ffffff;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
}

.no-product-invoice {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-product-invoice h5 {
    margin: 30px 0;
}

.no-product-invoice a svg {
    margin-right: 5px;
}

.company-add-header {
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-right: 40px;
}

.company-add-header h6 {
    font-size: 13px;
}

.company-add-header span {
    font-size: 11px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.add-discount-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}

.add-discount-all h5 {
    font-size: 14px;
}

.add-discount-all p {
    font-size: 12px;
}

.gst-fetch {
    position: relative;
}

.gst-fetch button {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #17c1e8;
    color: #ffffff;
    border: 1px solid #17c1e8;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
}

.customer-pays-you {
    position: relative;
}


.opening-balance {
    position: relative;
}

.opening-balance .select-credit {
    position: absolute;
    right: 0;
    bottom: 18px;
}

.select-credit a {
    color: #17c1e8;
    margin-left: 5px;
}

.custom-textarea {
    min-height: 70px;
}

.formp p {
    color: red;
}



.custom-snackbar {
    width: 100%;
    top: 0 !important;
}

.add-new-user-dialog h4,
.add-new-user-dialog .entered-gst-sec {
    text-align: center;
}

.add-new-user-dialog .radio-sec {
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.add-new-user-dialog .radio-sec label {
    margin-right: 50px;
}

.add-new-user-dialog .entered-gst-sec {
    width: 70%;
    margin: 0 auto;
}

.add-new-user-dialog .entered-gst-sec h5,
.add-new-user-dialog .entered-gst-sec p {
    margin-bottom: 15px;
}

.add-new-user-dialog .entered-gst-sec button {
    margin-bottom: 20px;
}

.add-new-user-dialog .dislog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0 30px;
}

.choose-busi-dropdown button {
    transform: scale(1) !important;
    text-transform: capitalize;
}

.go-to-next {
    text-align: right;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}
.filedcard{
    font-size: 14px !important;
    line-height: 24px !important;
    padding-left: 15px;
}
.filedBg{
    background: #C6F6D5 !important;
}
.filedBg .material-icons-round{
    color: #2F855A;
}
.notFiledBg{
   background-color: #FED7D7 !important;
}
.notFiledBg .material-icons-round{
    color: #C53030;
}
.gstLeftTab div{
    background-color: transparent !important;
}
.gstLeftTab button{
    padding: 12px 16px !important;
}
.upload-invoice-table table thead tr{
    white-space:nowrap;
}
.total-due a{
    color: #17c1e8;
}
.calender-picker{
    font-size: 1.25rem;
}